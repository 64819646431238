<template>
  <div>
    <!-- faq search section -->
    <!-- <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{
          backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`,
        }"
      >
        <b-card-body class="text-center">
          <h2 class="text-primary">
            Pertanyaan Umum
          </h2>
          <b-card-text class="mb-2">
            atau cari berdasarkan kata kunci
          </b-card-text>

          <b-form class="faq-search-input">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                v-model="faqSearchQuery"
                placeholder="kata kunci..."
              />
            </b-input-group>
          </b-form>
        </b-card-body>
      </b-card>
    </section> -->
    <!--/ faq search section -->

    <!-- frequently asked questions tabs pills -->
    <section id="faq-tabs">
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >
        <!-- payment tab -->
        <b-tab
          v-for="(categoryObj, categoryName, index) in faqData"
          :key="categoryName"
          :active="!index"
        >
          <!-- title -->
          <template #title>
            <feather-icon :icon="categoryObj.icon" size="18" class="mr-1" />
            <span class="font-weight-bold">{{ categoryObj.title }}</span>
          </template>

          <e-list :options="categoryObj" />
        </b-tab>
        <!--/ payment tab -->

        <!-- sitting lady image -->
        <!-- <template #tabs-end>
          <b-img
            fluid
            :src="require('@/assets/images/illustration/faq-illustrations.svg')"
            class="d-none d-md-block mt-auto"
          />
        </template> -->
        <!--/ sitting lady image -->
      </b-tabs>
    </section>
    <!--/ frequently asked questions tabs pills -->

    <!-- contact us -->
    <!-- <section class="faq-contact">
      <b-row class="mt-5 pt-75 text-center">
        <b-col cols="12">
          <h2>You still have a question?</h2>
          <b-card-text class="mb-3">
            If you cannot find a question in our FAQ, you can always contact us.
            We will answer to you shortly!
          </b-card-text>
        </b-col>
        <b-col sm="6">
          <b-card class="shadow-none py-1 faq-contact-card">
            <b-avatar size="42" rounded variant="light-primary" class="mb-2">
              <feather-icon icon="PhoneCallIcon" size="18" />
            </b-avatar>
            <h4>+ (810) 2548 2568</h4>
            <span class="text-body">We are always happy to help!</span>
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-card class="shadow-none py-1 faq-contact-card">
            <b-avatar size="42" rounded variant="light-primary" class="mb-2">
              <feather-icon icon="MailIcon" size="18" />
            </b-avatar>
            <h4>hello@help.com</h4>
            <span class="text-body">Best way to get answer faster!</span>
          </b-card>
        </b-col>
      </b-row>
    </section> -->
    <!--/ contact us -->
  </div>
</template>

<script>
import {
  // BCard,
  // BCardBody,
  // BForm,
  // BInputGroup,
  // BFormInput,
  // BCardText,
  // BInputGroupPrepend,
  BTabs,
  BTab,
  // BImg,
  // BRow,
  // BCol,
  // BAvatar,
} from 'bootstrap-vue'
// import { forEach } from 'postcss-rtl/lib/affected-props'
import EList from './EList.vue'

export default {
  components: {
    // BForm,
    // BCard,
    // BRow,
    // BCol,
    // BAvatar,
    // BCardBody,
    // BInputGroup,
    // BFormInput,
    // BCardText,
    // BInputGroupPrepend,
    BTabs,
    BTab,
    // BImg,
    EList,
  },
  data() {
    return {
      faqSearchQuery: '',
      faqData: '',
      faqDataMaster: {
        payment: {
          icon: 'BriefcaseIcon',
          title: 'Biodata',
          subtitle: '',
          qandA: [
            {
              question: 'Bagaimana cara memperbarui Biodata secara Mandiri?',
              ans:
                'Untuk penjelasan lebih lengkapnya silahkan klik link <strong><a href=https://docs.google.com/document/d/1DwvWxMgRtFhjgBaMqrG3CnoVnhJE9KZs9s1aSGLARR0/edit?usp=sharing target="_blank">disini</a></strong>',
            },
            {
              question: 'Data yang tidak dapat diperbarui secara Mandiri',
              ans:
                'Seluruh data dapat diperbarui sendiri secara mandiri, kecuali: Nama Lengkap, Nama pada KTPA, DPC, KTP dan Tanggal Lahir. Jika ingin merubah data tersebut, gunakan fitur chat box untuk mengajukan perubahan data.',
            },
            // {
            //   question: 'What does non-exclusive mean?',
            //   ans:
            //     'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan.',
            // },
            // {
            //   question:
            //     'Is the Regular License the same thing as an editorial license?',
            //   ans:
            //     'Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll sweet cupcake.',
            // },
            // {
            //   question:
            //     'Which Answer do I need for an end product that is only accessible to paying users?',
            //   ans:
            //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
            // },
            // {
            //   question:
            //     'Which Answer do I need to use an item in a commercial?',
            //   ans:
            //     'At tempor commodo ullamcorper a lacus vestibulum. Ultrices neque ornare aenean euismod. Dui vivamus arcu felis bibendum. Turpis in eu mi bibendum neque egestas congue. Nullam ac tortor vitae purus faucibus ornare suspendisse sed.',
            // },
            // {
            //   question:
            //     'Can I re-distribute an item? What about under an Extended License?',
            //   ans:
            //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Euismod lacinia at quis risus sed vulputate odio ut enim. Dictum at tempor commodo ullamcorper a lacus vestibulum.',
            // },
          ],
        },
        // delivery
        delivery: {
          icon: 'ArchiveIcon',
          title: 'Permohonan',
          subtitle: '',
          qandA: [
			/*
            {
              question:
                'Bagaimana cara mengajukan permohonan Verifikasi secara Online?',
              ans:
                'Ada 3 cara yang menjadi Anggota Advokat Peradi SAI yaitu : <ol>' +
                '<li>Advokat Baru (Penyumpahan) Para Advokat yang telah mengikuti Pengambilan Sumpah dan Pelantikan yang diselenggarakan oleh PERADI-SAI di wilayah hukum Pengadilan Tinggi di seluruh Indonesia.</li>' +
                '<li>Advokat Peradi SAI (Pendataan Ulang) Para Advokat yang telah tergabung dan terdaftar di Buku Daftar Advokat PERADI-SAI yang masa berlakunya Kartu Tanda Pengenal Advokat (KTPA) PERADI-SAI telah berakhir wajib melakukan Pendataan Ulang Advokat PERADI-SAI.</li>' +
                '<li>Advokat Pindahan (Verifikasi) Para Advokat yang telah memenuhi syarat dan sebelumnya tergabung di organisasi Advokat lain yang akan bergabung dengan PERADI-SAI.</li>' +
                '<li>Oleh karena itu, permohonan verifikasi dilakukan secara manual.</li>' +
                '</ol>',
            },
			*/
            {
              question:
                'Bagaimana cara mengajukan permohonan Data Ulang secara Online?',
              ans:
                'Download <strong><a href=https://docs.google.com/document/d/1j7kEvQT4mpJYIsxZ3tVi52NwgxC_pRiJRHSh6JOsioQ/edit?usp=sharing target="_blank">disini</a></strong>',
            },
          ],
        },
        // cancellation and return
        // cancellationReturn: {
        //   icon: 'BookOpenIcon',
        //   title: 'Magang',
        //   subtitle: 'Which Answer do I need?',
        //   qandA: [
        //     {
        //       question:
        //         'Can my security guard or neighbour receive my shipment if I am not available?',
        //       ans:
        //         'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.',
        //     },
        //     {
        //       question:
        //         'How can I get the contact number of my delivery agent?',
        //       ans:
        //         'Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.',
        //     },
        //     {
        //       question: 'How can I cancel my shipment?',
        //       ans:
        //         'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan.',
        //     },
        //     {
        //       question:
        //         'I have received a defective/damaged product. What do I do?',
        //       ans:
        //         'Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll sweet cupcake.',
        //     },
        //     {
        //       question: 'How do I change my delivery address?',
        //       ans:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        //     },
        //     {
        //       question:
        //         'What documents do I need to carry for self-collection of my shipment?',
        //       ans:
        //         'At tempor commodo ullamcorper a lacus vestibulum. Ultrices neque ornare aenean euismod. Dui vivamus arcu felis bibendum. Turpis in eu mi bibendum neque egestas congue. Nullam ac tortor vitae purus faucibus ornare suspendisse sed. Commodo viverra maecenas accumsan lacus vel facilisis volutpat est velit. Tortor consequat id porta nibh. Id aliquet lectus proin nibh nisl condimentum id venenatis a. Faucibus nisl tincidunt eget nullam non nisi. Enim nunc faucibus a pellentesque. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus. Nec nam aliquam sem et tortor consequat id. Fringilla est ullamcorper eget nulla facilisi. Morbi tristique senectus et netus et.',
        //     },
        //     {
        //       question:
        //         'What are the timings for self-collecting shipments from the Delhivery Branch?',
        //       ans:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Euismod lacinia at quis risus sed vulputate odio ut enim. Dictum at tempor commodo ullamcorper a lacus vestibulum.',
        //     },
        //   ],
        // },
        // my orders
        // myOrders: {
        //   icon: 'UserCheckIcon',
        //   title: 'Pengangkatan Advokat',
        //   subtitle: 'Which Answer do I need?',
        //   qandA: [
        //     {
        //       question: 'Can I avail of an open delivery?',
        //       ans:
        //         'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.',
        //     },
        //     {
        //       question:
        //         'I haven’t received the refund of my returned shipment. What do I do?',
        //       ans:
        //         'Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.',
        //     },
        //     {
        //       question: 'How can I ship my order to an international location?',
        //       ans:
        //         'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan.',
        //     },
        //     {
        //       question:
        //         'I missed the delivery of my order today. What should I do?',
        //       ans:
        //         'Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll sweet cupcake.',
        //     },
        //     {
        //       question:
        //         'The delivery of my order is delayed. What should I do?',
        //       ans:
        //         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        //     },
        //   ],
        // },
        // product and services
        // productServices: {
        //   icon: 'BookIcon',
        //   title: 'Pengambilan Sumpah',
        //   subtitle: 'Which Answer do I need?',
        //   qandA: [
        //     {
        //       question:
        //         'How can I register a complaint against the courier executive who came to deliver my order?',
        //       ans:
        //         'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.',
        //     },
        //     {
        //       question:
        //         'The status for my shipment shows as ‘not picked up’. What do I do?',
        //       ans:
        //         'Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.',
        //     },
        //     {
        //       question: 'How can I get a proof of delivery for my shipment?',
        //       ans:
        //         'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan.',
        //     },
        //     {
        //       question: 'How can I avail your services?',
        //       ans:
        //         'Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll sweet cupcake.',
        //     },
        //   ],
        // },
        // product and services
        // keanggotaan: {
        //   icon: 'UsersIcon',
        //   title: 'Keanggotaan',
        //   subtitle: 'Which Answer do I need?',
        //   qandA: [
        //     {
        //       question:
        //         'How can I register a complaint against the courier executive who came to deliver my order?',
        //       ans:
        //         'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.',
        //     },
        //     {
        //       question:
        //         'The status for my shipment shows as ‘not picked up’. What do I do?',
        //       ans:
        //         'Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.',
        //     },
        //     {
        //       question: 'How can I get a proof of delivery for my shipment?',
        //       ans:
        //         'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan.',
        //     },
        //     {
        //       question: 'How can I avail your services?',
        //       ans:
        //         'Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll sweet cupcake.',
        //     },
        //   ],
        // },
      },
    }
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {
        const filteredData = {}
        const queryLowered = this.faqSearchQuery.toLowerCase()
        if (this.faqSearchQuery) {
          Object.entries(this.faqDataMaster).forEach(entry => {
            const [categoryName, categoryObj] = entry
            const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj =>
              qAndAObj.question.toLowerCase().includes(queryLowered),
            )
            if (filteredQAndAOfCategory.length) {
              filteredData[categoryName] = {
                ...categoryObj,
                qandA: filteredQAndAOfCategory,
              }
            }
          })
          this.faqData = filteredData
        } else {
          this.faqData = this.faqDataMaster
        }
      },
    },
  },
  methods: {
    fetchData() {
      this.$http
        .get('/faq/data', { params: { q: this.faqSearchQuery } })
        .then(res => {
          this.faqData = res.data
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
